import { Typography } from '@mui/material'
import React from 'react'

const CompanyAddress: React.FC = () => {
  return (
    <>
      <Typography variant="subHeading">Address</Typography>
      <address itemScope itemType="http://schema.org/PostalAddress">
        <span itemProp="name">Zuto Limited address:</span>
        <span itemProp="streetAddress">
          Winterton House,
          <br />
          Winterton Way,
        </span>
        <span itemProp="addressRegion">Macclesfield,</span>
        <span itemProp="postalCode">SK11 0LP</span>
      </address>
    </>
  )
}

export default CompanyAddress
