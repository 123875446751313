import $console from '../$console'
import { fireClientSideKibanaLog } from '../Logging/LoggingUtils'

export const reportCSPViolation = async (report: any) => {
  const { blockedURI, documentURI, referrer, violatedDirective } = report['csp-report']
  try {
    fireClientSideKibanaLog('CSP violation report', 'CSP violation reported successfully', 'Info', {
      eventBlockedUri: blockedURI,
      eventDocumentUri: documentURI,
      eventReferrer: referrer,
      eventViolatedDirective: violatedDirective
    })
  } catch (error) {
    console.log(`unable to send ${error?.eventType}, event: ${error?.message}`)
    $console.error('zutoAnalyticsHelper.sendCustomEvent error', error)
  }
}
