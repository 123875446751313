import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material'
import CallIcon from '@mui/icons-material/Call'
import PersonIcon from '@mui/icons-material/Person'
import Link from 'next/link'
import { dFlexCenter, dFlexSpaceBetween, layoutMaxWidth } from '../../../../Theme/UtilityStyles'
import Button from '@mui/material/Button'
import { colors } from '../../../../Theme/LegacyTheme'
import Image from 'next/image'
import MobileMenu from './MobileMenu'
import Nav from './Nav'
import { ContactVariables } from '../../../../GlobalVariables/Contact'
import { BaseComponentProps } from '@/types/UI'

const ZutoHeaderBase = styled('header')(({ theme }) => ({
  flex: 1,
  flexWrap: 'wrap',
  padding: `16px 0 0`,
  minHeight: 70,
  '& .header-top': {
    ...dFlexSpaceBetween,
    ...layoutMaxWidth,
    flex: 1,
    flexWrap: 'wrap',
    span: {
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        paddingTop: 7.3
      }
    }
  },
  '& .left-col': {
    display: 'flex'
  },
  '& .right-col': {
    ...dFlexCenter,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto'
    },
    a: {
      textDecoration: 'none'
    }
  },
  '& .contact-number': {
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(5),
      svg: {
        marginRight: theme.spacing(2)
      }
    },
    a: {
      ...dFlexCenter,
      flexDirection: 'column',
      color: colors.black,
      textDecoration: 'none',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row'
      }
    }
  },
  '& .sign-in': {
    a: {
      [theme.breakpoints.down('lg')]: {
        ...dFlexCenter,
        flexDirection: 'column',
        color: colors.black,
        textDecoration: 'none'
      }
    }
  }
}))

const ZutoHeaderMobile = styled(ZutoHeaderBase)(({ theme }) => ({
  display: 'flex',
  paddingBottom: '16px',
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}))

const ZutoHeaderDesktop = styled(ZutoHeaderBase)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    minHeight: 136
  }
}))

const Header = ({ zutoUrl }: BaseComponentProps) => {
  const [signInText, setSignInText] = useState('Sign in')

  useEffect(() => {
    const authToken = document.cookie.split('; ').find((row) => row.startsWith('auth_token='))
    if (authToken) {
      setSignInText('My Account')
    }
  }, [])

  const renderLogo = () => {
    return (
      <Link href={zutoUrl} role="link">
        <Image
          src="https://cdn.zuto.cloud/assets/images/zuto-logo.svg"
          alt="Zuto Logo"
          width={112}
          height={40}
          loading="eager"
        />
      </Link>
    )
  }

  const renderMobileActions = () => {
    return (
      <>
        <div className="contact-number">
          <Link href={`tel:${ContactVariables.zutoContactNumber}`} role="link">
            <CallIcon
              sx={{
                color: (theme) => theme.palette.primary.main
              }}
            />
            <span>Call us</span>
          </Link>
        </div>
        <div className="sign-in">
          <Link href={`${zutoUrl}/sign-in/`} role="link">
            <>
              <PersonIcon
                sx={{
                  color: (theme) => theme.palette.primary.main
                }}
              />
              <span>{signInText}</span>
            </>
          </Link>
        </div>
      </>
    )
  }

  const renderDesktopActions = () => {
    return (
      <>
        <div className="contact-number">
          <Link href={`tel:${ContactVariables.zutoContactNumber}`} role="link">
            <CallIcon
              sx={{
                color: (theme) => theme.palette.primary.main
              }}
            />
            <span>{ContactVariables.zutoContactNumber}</span>
          </Link>
        </div>
        <div className="sign-in">
          <Button
            href={`${zutoUrl}/sign-in/`}
            variant="text"
            role="link"
            aria-label={signInText}
            id="tag_sign-in-btn"
          >
            {signInText}
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <ZutoHeaderMobile>
        <div className="header-top">
          <div className="left-col">{renderLogo()}</div>
          <div className="right-col">{renderMobileActions()}</div>
        </div>
        <MobileMenu />
      </ZutoHeaderMobile>
      <ZutoHeaderDesktop>
        <div className="header-top">
          <div className="left-col">{renderLogo()}</div>
          <div className="right-col">{renderDesktopActions()}</div>
        </div>
        <Nav />
      </ZutoHeaderDesktop>
    </>
  )
}

export default Header
