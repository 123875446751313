import { NextApiRequest } from 'next'
import sendAnalyticsEvent, { AnalyticsEventData, KibanaEventCategory, KibanaEventLevel } from '../../services/ZutoAnalyticsService'
import ZutoAnalyticsHelper from '@zuto-internal/zuto-analytics-helper'

interface LogParams {
    req?: NextApiRequest
    isDeclinedAndNotFraud: boolean
    isApplicationOlderThanSixMonths: boolean
    applicationDate?: string
}

const zutoAnalyticsHelper = new ZutoAnalyticsHelper()

/**
 * Sends a custom logging event to Kibana, specifying the category, message, and level of the event.
 * This function can optionally include additional properties that are merged into the event object.
 * Additional properties should be prefixed with "event" to maintain consistency with Kibana's event structure.
 *
 * @param {KibanaEventCategory} category - The category of the event, used to classify the type of information being logged.
 * @param {string} message - The message or description associated with the event, providing more details about what is being logged.
 * @param {KibanaEventLevel} level - The severity level of the event (e.g. error, info).
 * @param {object} [additionalProperties] - Optional additional properties to include in the event data.
 *    These additional properties should follow the naming convention, starting with "event" (e.g., `eventUserId`, `eventAction`).
 * @returns {void}
 */
export const fireClientSideKibanaLog = (category: KibanaEventCategory, message: string, level: KibanaEventLevel, additionalProperties?: object) => {
  zutoAnalyticsHelper.sendCustomEvent({
      eventType: 'acq-components',
      eventCategory: category,
      eventMessage: message,
      eventLevel: level,
      eventDeviceId: 'na',
      eventSessionId: 'na',
      ...additionalProperties
  })
}

/**
 * Asynchronously sends a server-side logging event to Kibana, capturing the necessary event details including the category, message, and level of the event.
 * This function is designed to work with Next.js API requests, and it extracts device and session IDs from the request headers.
 * It can also include additional properties in the event data, which should be provided as part of the `additionalProperties` object.
 *
 * @param {NextApiRequest | undefined} req - The API request object from Next.js, used to extract headers for device and session IDs.
 *   If `req` is `undefined`, 'not present' is used for both device and session IDs.
 * @param {KibanaEventCategory} category - The category of the event, used to classify the type of information being logged.
 * @param {string} message - The message or description associated with the event, providing more details about what is being logged.
 * @param {KibanaEventLevel} level - The severity level of the event (e.g. error, info).
 * @param {object} [additionalProperties] - Optional additional properties to include in the event data. These are merged into the `additionalData` field of the event object.
 * @returns {Promise<void>} - A promise that resolves when the analytics event has been sent.
 */
export const fireServerSideKibanaLog = 
async (req: NextApiRequest | undefined, category: KibanaEventCategory, message: string, level: KibanaEventLevel, additionalProperties?: object) => {
  const errorInfo: AnalyticsEventData = {
    type: 'acq-components',
    category: category,
    deviceId: (req?.headers?.device as string) ?? 'not present',
    sessionId: (req?.headers?.session as string) ?? 'not present',
    additionalData: {
      message: message,
      level: level,
      ...additionalProperties
    }
  }
  await sendAnalyticsEvent(errorInfo)
}

export const logDeclinedApplicationStatus = async ({
    req, isDeclinedAndNotFraud, isApplicationOlderThanSixMonths, applicationDate}: LogParams): Promise<void> => {
    if (isDeclinedAndNotFraud) {
      const logMessage = isApplicationOlderThanSixMonths ? 'Declined 6 months ago or greater' : 'Declined less than 6 months ago'
      fireServerSideKibanaLog(req, 'Meta API', logMessage, 'Info', { applicationDate: new Date(applicationDate || '') })
    }
}
