import * as React from 'react'
import { styled } from '@mui/material'
import { colors } from '../../../../Theme/LegacyTheme'
import { layoutMaxWidth } from '../../../../Theme/UtilityStyles'
import Link from 'next/link'
import { dFlexSpaceBetween } from '../../../../Theme/UtilityStyles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { menuItems } from './NavContent'

const ZutoNav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: 0,
  flex: 1,
  flexBasis: '100%',
  backgroundColor: colors.black,
  margin: `${theme.spacing(3)} 0 0`,
  '& .nav-inner': {
    ...layoutMaxWidth,
    flex: 1
  },
  li: {
    position: 'relative'
  },
  ul: {
    ...dFlexSpaceBetween,
    paddingLeft: theme.spacing(0),
    marginBottom: 0,
    '&.primary-nav': {
      marginTop: 0,
      listStyle: 'none',
      fontSize: '15px',
      '& >li:hover .secondary-nav': {
        display: 'flex'
      },
      a: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(3)} 8px`,
        '&:hover': {
          color: '#fff'
        }
      },
      span: {
        '&.has-children': {
          display: 'flex',
          alignItems: 'center',
          padding: `${theme.spacing(2)} 8px`,
          color: colors.white,
          cursor: 'pointer'
        }
      },
      svg: {
        fontSize: 28,
        marginLeft: 5
      }
    },
    '&.secondary-nav': {
      display: 'none',
      position: 'absolute',
      top: '100%',
      right: 0,
      left: 0,
      zIndex: 20,
      backgroundColor: colors.white,
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 300,
      padding: 32,
      borderRadius: '0 0 20px 20px',
      boxShadow: '0 10px 30px -15px rgba(0,0,0,.15)',
      fontWeight: 400,
      fontSize: 16,
      listStyle: 'none',
      a: {
        display: 'block',
        padding: '12px 0',
        color: colors.black,
        '&:hover': {
          color: colors.hoverBlue,
          textDecoration: 'none'
        }
      }
    }
  },
  a: {
    color: colors.white,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

const Nav = () => {
  return (
    <ZutoNav sx={{ flex: 1, flexBasis: '100%' }}>
      <div className="nav-inner">
        <ul className="primary-nav">
          {menuItems.map((item) => (
            <li key={item.label}>
              {item.subItems ? (
                <span className="has-children">
                  <span>{item.label}</span>
                  <KeyboardArrowDownIcon />
                </span>
              ) : (
                <Link href={item.path} role="link">
                  <span>{item.label}</span>
                </Link>
              )}
              {item.subItems && (
                <ul className="secondary-nav">
                  {item.subItems.map((subItem) => (
                    <li key={subItem.label}>
                      <Link href={subItem.path} role="link">
                        {subItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </ZutoNav>
  )
}

export default Nav
