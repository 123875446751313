import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { styled } from '@mui/material'
import { colors } from '../../../../Theme/LegacyTheme'
import { usePathname } from 'next/navigation'
import { menuItems } from './NavContent'

export interface MenuItem {
  label: string
  path: string
  subItems?: MenuItem[]
}

const HamburgerButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `0 ${theme.spacing(4)} 0 0`,
  '& .menu-label': {
    paddingTop: 0,
    fontSize: 14
  }
}))

const MobileMenuContainer = styled('div')(({ theme }) => ({
  display: 'none',
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  transition: 'left 0.3s ease',
  position: 'relative',
  flexBasis: '100%',
  '&.open': {
    display: 'block',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '90vh',
      top: '100%',
      background: 'rgba(0, 0, 0, 0.2)',
      pointerEvents: 'none',
      zIndex: 1
    }
  },

  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },

  '& > ul': {
    marginTop: '5px',
    marginBottom: 0
  },

  '& .top-level-navigation': {
    padding: '12px 20px'
  },

  '& li a': {
    textDecoration: 'none',
    color: colors.black
  },

  '& li span': {
    cursor: 'pointer'
  },

  '& .submenu': {
    marginLeft: theme.spacing(0),
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    opacity: 0,
    transform: 'translateX(-100%)',

    li: {
      padding: '12px 0',

      '&:last-of-type': {
        paddingBottom: 0
      }
    },

    '&.open': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },

  '& .back-button': {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,

    '> label': {
      opacity: 0
    }
  },

  '& .submenu-open': {
    '& .top-level-navigation:not(.children-open)': {
      display: 'none'
    },

    '& .top-level-navigation.children-open > span': {
      display: 'none'
    }
  },

  '& .sub-items-wrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const MobileMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [currentSubMenu, setCurrentSubMenu] = useState<MenuItem | null>(null)
  const pathname = usePathname()
  const [mobileMenuDomEl, setMobileMenuDomEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const menuElement = document.querySelector('.mobile-menu') as HTMLElement | null
      setMobileMenuDomEl(menuElement)
    }
  }, [])

  // Reset menu to closed if URL path changes
  useEffect(() => {
    if (mobileMenuDomEl) {
      setMenuOpen(false)
      mobileMenuDomEl.classList.remove('open')
    }
  }, [pathname, mobileMenuDomEl])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    if (mobileMenuDomEl) {
      if (mobileMenuDomEl.classList.contains('open')) {
        mobileMenuDomEl.classList.remove('open')
      } else {
        mobileMenuDomEl.classList.add('open')
      }
    }
  }

  const handleSubMenu = (subMenu: MenuItem) => {
    setCurrentSubMenu(subMenu)
    // Close other open submenus on the same level
    const otherSubmenus = document.querySelectorAll(
      '.has-children.children-open'
    ) as NodeListOf<HTMLElement>
    otherSubmenus.forEach((otherSubMenu) => {
      if (otherSubMenu.id !== subMenu.label) {
        otherSubMenu.classList.remove('children-open')
      }
    })

    const span = document.getElementById(subMenu.label)
    if (span) {
      span.classList.add('children-open')
      const parentUl = span.closest('ul')
      if (parentUl) {
        parentUl.classList.add('submenu-open')
      }
    }
  }

  const goBack = () => {
    setCurrentSubMenu(null)
    // Remove the class when going back
    const span = document.getElementById(currentSubMenu?.label || '')
    if (span) {
      span.classList.remove('children-open')
      const parentUl = span.closest('ul')
      if (parentUl) {
        parentUl.classList.remove('submenu-open')
      }
    }
  }

  return (
    <>
      <HamburgerButtonContainer onClick={toggleMenu}>
        {menuOpen ? (
          <CloseIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: 'pointer',
              width: 33,
              height: 33
            }}
          />
        ) : (
          <MenuIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: 'pointer',
              width: 33,
              height: 33
            }}
          />
        )}
        <span className="menu-label">Menu</span>
      </HamburgerButtonContainer>

      <MobileMenuContainer className={`mobile-menu ${menuOpen || currentSubMenu ? 'open' : ''}`}>
        <ul>
          {menuItems.map((item) => (
            <li
              key={item.label}
              className={`top-level-navigation ${currentSubMenu === item ? 'children-open' : ''}`}
            >
              {item.subItems ? (
                <span className="sub-items-wrapper" onClick={() => handleSubMenu(item)}>
                  <span id={item.label}>{item.label}</span>
                  <KeyboardArrowRightIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: 'pointer',
                      width: 28,
                      height: 28
                    }}
                  />
                </span>
              ) : (
                <Link href={item.path} role="link">
                  {item.label}
                </Link>
              )}
              {currentSubMenu === item && (
                <ul className={`submenu open`}>
                  <li>
                    <span onClick={goBack} className="back-button">
                      <KeyboardArrowLeftIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: 'pointer',
                          width: 28,
                          height: 28
                        }}
                      />
                      {item.label}
                      <label>Back</label>
                    </span>
                  </li>
                  {item.subItems &&
                    item.subItems.map((subItem) => (
                      <li key={subItem.label}>
                        <Link href={subItem.path} role="link">
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </MobileMenuContainer>
    </>
  )
}

export default MobileMenu
