import $console from '../utils/$console'
import axios from 'axios'

export type KibanaEventCategory = 
'Password Changed API' 
| 'Change Password API'
| 'Meta API'
| 'Contact API'
| 'Simple Footer API'
| 'Declined Summary API'
| 'My Zuto'
| 'CSP violation report'
| 'Experiment Service'
| 'Application Service'

export type KibanaEventLevel = 
'Info'
| 'Error'

export type AnalyticsEventData = {
  type: string
  category: KibanaEventCategory
  deviceId: string
  sessionId: string
  additionalData: Record<string, string>
}

function buildAnalyticsQuery(data : AnalyticsEventData) : string {
  const additionalDataQuery = Object.keys(data.additionalData).map((key) => `&${key}=${data.additionalData[key]}`).join('')
  return `?type=${data.type}&category=${data.category}&device=${data.deviceId}&session=${data.sessionId}`
    + `${additionalDataQuery}`
}

export default async function sendAnalyticsEvent(data : AnalyticsEventData) : Promise<void> {
  try {
    const query = buildAnalyticsQuery(data)
    await axios.get(`${process.env.ZUTO_ANALYTICS_URL}/${query}`)
  } catch {(error: { type: string; message?: string }) => {
    $console.error(`unable to send ${error?.type}, event: ${error?.message}`)
  }
  }
}
