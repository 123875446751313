import { MenuItem } from './MobileMenu'

export const menuItems: MenuItem[] = [
  {
    label: 'Car finance',
    path: '/car-finance/',
    subItems: [
      { label: 'Bad credit car finance', path: '/bad-credit-car-finance/' },
      { label: 'HP car finance', path: '/hp-car-finance/' },
      { label: 'PCP car finance', path: '/pcp-car-finance/' },
      { label: 'No deposit car finance', path: '/no-deposit-car-finance/' },
      { label: 'Electric car finance', path: '/electric-car-finance/' },
      {
        label: 'Negative equity car finance',
        path: '/negative-equity-car-finance/'
      },
      { label: 'Refinance car', path: '/refinance-car/' }
    ]
  },
  {
    label: 'Car finance calculator',
    path: '/car-finance-calculator/'
  },
  {
    label: 'Car finance explained',
    path: '/car-finance-explained/'
  },
  {
    label: 'FAQs and guides',
    path: '/faqs-and-guides/',
    subItems: [
      { label: 'Blog', path: '/blog/' },
      { label: 'FAQs', path: '/help-and-advice/' },
      { label: 'Car finance glossary', path: '/car-finance-glossary/' }
    ]
  },
  {
    label: 'Contact us',
    path: '/contact-us/'
  }
]
