import { Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../../../Theme/LegacyTheme'

const Disclaimer: React.FC = () => {
  return (
    <>
      <Typography variant="body1" sx={{ color: colors.darkGrey }}>
        Zuto is credit broker, not a lender. Zuto Limited is authorised and regulated by the
        Financial Conduct Authority, registration number 452589. All finance is subject to status
        and income.
      </Typography>
      <Typography variant="body1" sx={{ color: colors.darkGrey }}>
        Applicants must be 18 or over, terms and conditions apply, guarantees and indemnities may be
        required. Certain exclusions for NI residents. Zuto won&apos;t charge you anything for our
        service but we will receive a commission from the lender which varies based on the product,
        amount borrowed or your credit score. This doesn&apos;t affect the amount you pay. Written
        quotation on request.
      </Typography>
    </>
  )
}

export default Disclaimer
