'use client'
import { styled } from '@mui/material'
import '../src/Theme/UtilityClasses.css'
import '../src/Theme/Fonts.css'
import FooterColumn from '../src/components/UI/Shared/Footer/FooterColumn'
import CompanyAddress from '../src/components/UI/Shared/Footer/CompanyAddress'
import Disclaimer from '../src/components/UI/Shared/CompanyInformation/Disclaimer'
import Header from '../src/components/UI/Shared/Header/Header'
import { layoutMaxWidth } from '../src/Theme/UtilityStyles'
import SocialIcons from '@/components/UI/Shared/Social/SocialIcons'
import { reportCSPViolation } from '../src/utils/csp/reportCSP'
import { useEffect } from 'react'

export const BodyContainer = styled('main')(({ theme }) => ({
  ...layoutMaxWidth,
  margin: '0 auto',
  padding: `${theme.spacing(6)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`
}))

const Footer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(5)}`,
  '& .footer': {
    '&__inner': {
      ...layoutMaxWidth,
      ul: {
        marginBottom: theme.spacing(4)
      }
    },
    '&__links': {
      display: 'flex'
    },
    '&__social-media': {
      margin: `${theme.spacing(5)} 0`
    }
  },
  address: {
    fontStyle: 'normal',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.6
  }
}))

export default function RootLayoutChildren({
  children,
  zutoUrl
}: {
  children: React.ReactNode
  zutoUrl: string
}) {
  const companyLinks = [
    { text: 'About Zuto', url: `${zutoUrl}/about-zuto/` },
    { text: 'Contact us', url: `${zutoUrl}/contact-us/` },
    { text: 'Careers', url: 'https://careers.zuto.com/' },
    { text: 'Gender pay report', url: `${zutoUrl}/gender-pay-report/` },
    { text: 'B Corp impact report', url: `${zutoUrl}/bcorp-impact-report/` },
    { text: 'Press office', url: `${zutoUrl}/press-office/` },
    { text: 'Modern slavery statement', url: `${zutoUrl}/modern-slavery-statement/` },
    {
      text: 'Customer care and complaints',
      url: `${zutoUrl}/customer-care-and-complaints/`
    }
  ]

  const legalLinks = [
    { text: 'Terms & conditions', url: `${zutoUrl}/terms-and-conditions/` },
    { text: 'Privacy policy', url: `${zutoUrl}/privacy/` },
    { text: 'Cookie policy', url: `${zutoUrl}/cookies/` },
    {
      text: 'Initial disclosure document',
      url: `${zutoUrl}/initial-disclosure-document/`
    },
    { text: 'Sitemap', url: `${zutoUrl}/sitemap/` }
  ]

  useEffect(() => {
    window.addEventListener('securitypolicyviolation', (e) => {
      reportCSPViolation({
        'csp-report': {
          documentURI: e.documentURI,
          referrer: e.referrer,
          blockedURI: e.blockedURI,
          violatedDirective: e.violatedDirective,
          originalPolicy: e.originalPolicy
        }
      })
    })
    return () => window.removeEventListener('resecuritypolicyviolationize', reportCSPViolation)
  }, [])

  return (
    <>
      <Header zutoUrl={zutoUrl} />
      {children}
      <Footer>
        <div className="footer__inner">
          <div className="footer__links">
            <FooterColumn title="Company" links={companyLinks}></FooterColumn>
            <FooterColumn title="Legal" links={legalLinks}>
              <CompanyAddress />
            </FooterColumn>
          </div>
          <div className="footer__social-media">
            <SocialIcons />
          </div>
          <div className="footer__disclaimer">
            <Disclaimer />
          </div>
        </div>
      </Footer>
    </>
  )
}
